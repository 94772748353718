import React, { useEffect, useState } from 'react';
import Happy from '../assets/happy.png';
import Logo from '../assets/Web_logo.png';

const RedirectingPage = () => {
    const [screenSize, setScreenSize] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const redirect = (page) => {
        if (page === 'Coach') {
            window.location.href = 'https://coach.thap.in';
        } else if (page === 'Mobile') {
            if (screenSize) {
                window.location.href = 'https://thap.in';
            } else {
                alert('Select the option from Mobile to open your mobile app');
            }
        } else {
            window.location.href = '/';
        }
    };

    return (
        <div className="privacy-main">
            <div className="top-main">
                <img src={Logo} style={{ width: '20vh' }} alt="Logo" />
            </div>
            <div className="container">
                <div className="privacy-content">
                    <div className="brain-img">
                        <img src={Happy} style={{ height: '20vh' }} alt="Happy" />
                    </div>
                    <div className="redirecting-buttons" style={{ display: 'flex', justifyContent: 'space-evenly', marginBlock: '40px' }}>
                        <div className="coach-app" onClick={() => redirect('Coach')} style={{ background: "#FF8F55", padding: '10px 20px', borderRadius: '20px', cursor: 'pointer', fontWeight: '500' }}>
                            Coach App
                        </div>
                        <div className="mobile-app" onClick={() => redirect('Mobile')} style={{ background: "#FF8F55", padding: '10px 20px', borderRadius: '20px', cursor: 'pointer', fontWeight: '500' }}>
                            Mobile App
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RedirectingPage;
