import React, { useState, useLayoutEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import RenewComponent from './RenewComponent';
import Policies from './Policies';
import RedirectingPage from './RedirectingComponent';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const [width, height] = useWindowSize();
  return (
    <div className="App" style={{ height, width }} >
      <Router>
        <Routes>
          <Route path='/' element={<RedirectingPage />} />
          <Route path='/renew' element={<RenewComponent />} />
          <Route exact path='privacy-policy.html' element={<Policies />} />
          {/* <Route exact path='privacy-policy.html' element={} />
          <Route exact path='privacy-policy.html' element={} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
