import Happy from '../assets/happy.png';
import Logo from '../assets/Web_logo.png';
import { useEffect, useState } from 'react';

const RenewComponent = () => {
    const [success, setSuccess] = useState(false)
    const [pass, setPass] = useState({ pass1: '', pass2: '' })
    const [dtype, setdtype] = useState('')
    const onSubmit = () => {
        console.log("submitted")
        if (pass.pass1 !== pass.pass2) {
            alert("Password's does not match")
        } else if (pass.pass1.length <= 8) {
            alert("Password length should be minimum of 8 characters")
        } else if (pass.pass1 === pass.pass2) {
            const obs = { ...Object.assign(...window.location.search.replace(/\?/g, '').split('&').map((d) => ({ [d.split('=')[0]]: d.split('=')[1] }))), password: pass.pass1 }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(obs)
            };
            fetch('https://stagingapi.thap.in/thapV5/update_password', requestOptions).then(res => res.json()).then(data => {
                if (data.status === 200) {
                    setSuccess(true)
                    alert("Password updated successfully. Kindly login with your mobile app.")
                }
            })
        }
    }

    useEffect(() => {
        if (dtype === 'Android') {
            window.open('https://thap.in/login', "_blank")
        } else if (dtype === 'iOS') {
            window.open('thap://thap.in/login', "_blank")
        }
    }, [dtype])

    const setPass1 = (e) => {
        setPass({ ...pass, pass1: e.target.value })
    }

    const setPass2 = (e) => {
        setPass({ ...pass, pass2: e.target.value })
    }
    return <div className="privacy-main">
        <div className="top-main">
            <img src={Logo} style={{ width: '20vh' }} alt="" />
        </div>
        <div className="container">
            <div className="privacy-content" >
                <div className="brain-img">
                    <img src={Happy} style={{ height: '20vh', }} alt="" />
                </div>
                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                    <h3>
                        Reset Password
                    </h3>
                    <input type={"password"} onChange={setPass1} value={pass.pass1} placeholder="New password" style={{ width: '10vw', minWidth: '200px', padding: '4px 10px', margin: '5px' }} />
                    <input type={"password"} onChange={setPass2} value={pass.pass2} placeholder="Confirm password" style={{ width: '10vw', minWidth: '200px', padding: '4px 10px', margin: '5px' }} />
                    <input type={"button"} onClick={onSubmit} value="Submit" style={{ width: '10vw', minWidth: '200px', padding: '5px 10px', margin: '5px', backgroundColor: '#fd1c70', borderColor: '#f11063', borderRadius: '10px', color: '#fff', fontSize: '16px' }} />
                    {success ? <>
                        <h3>Open login from</h3>
                        <div style={{ flexDirection: 'row', display: 'flex' }} >
                            <input type={"button"} onClick={() => setdtype('iOS')} value="iOS" style={{ padding: '5px 10px', margin: '5px', backgroundColor: '#fd1c70', borderColor: '#f11063', borderRadius: '10px', color: '#fff', fontSize: '12px' }} />
                            <input type={"button"} onClick={() => setdtype('Android')} value="Android" style={{ padding: '5px 10px', margin: '5px', backgroundColor: '#fd1c70', borderColor: '#f11063', borderRadius: '10px', color: '#fff', fontSize: '12px' }} />
                        </div>
                    </> : <></>}
                </div>
            </div>
        </div>
    </div>
}


export default RenewComponent;