import Happy from '../assets/happy.png';
import Logo from '../assets/Web_logo.png';

const Policies = ()=>{

    return <>
    <div class="privacy-main">
        <div class="top-main">
            <img src={Logo} style={{ width: '20vh' }} alt=""/>
        </div>
        <div class="container">
            <div class="privacy-content mt-5 mb-5">
                <h1><center>THAP Privacy Policy</center></h1>
                <div class="brain-img">
                    <img src={Happy} style={{ height: '20vh',  }} alt=""/>
                </div>
                <div class="text-sec">
                    <p>We are dedicated to protecting the privacy and security of the information you entrust to us at
                        THAP. The policies and procedures governing the collection, use, and disclosure of Personal
                        Information from users are outlined in our Privacy Policy. Please take the time to read this
                        Privacy Statement carefully. We protect the client's anonymity and work in line with
                        confidentiality principles to ensure that all personal and health information obtained is
                        maintained and delivered in a highly secure environment, in order to ensure the client's
                        privacy. If any of the conditions of this Privacy Policy do not comply with the applicable laws
                        of your country, it is recommended that you do not use the website, mobile application(s), or
                        connected Services.</p>
                </div>
                <div class="text-sec">
                    <h3>Definitions</h3>
                    <h4>Company</h4>
                    <p>“THAP”, “The Happiness Project”, “The BlacBook”, “Company”, “Firm”, “we”, “our”, “us”, “Service
                        Provider” or similar terminology are all in reference to THAP an initiative by The BlacBook as a
                        provider of Services for the remainder of this document.</p>
                </div>
                <div class="text-sec">
                    <h4>User</h4>
                    <p>“Client”, “user”, “you”, “your” or other similar terminology are all in reference to you, as the
                        user of the website, mobile application(s) and as a recipient of our Services and resources for
                        the remainder of this document.</p>
                </div>
                <div class="text-sec">
                    <h4>Platform</h4>
                    <p>“Platform” or similar terminology are all in reference to any mobile app, website or web links
                        that the User can employ to access Services provided by the Company.</p>
                </div>
                <div class="text-sec">
                    <h4>Psychological Wellness Professionals</h4>
                    <p>“Psychological Wellness Practitioner”, “Psychological Wellness Professional”, “Wellness
                        Professional”, “therapist”, “wellness advisor”, “expert”, “doctor”, “consultant”, “counsellor”
                        or similar terminology are all in reference to the Psychological Wellness Practitioner.</p>
                </div>
                <div class="text-sec">
                    <h4>Nature of Service</h4>
                    <p>THAP is a platform for psychological wellbeing that provides emotional wellness products and
                        services to individuals and businesses. These include, but are not limited to, corporate
                        wellness programs through which employees of organizations have access to a variety of goods and
                        services.</p>
                    <ul>
                        <li> Online and in-person consultations with qualified Psychological Wellness Professionals</li>
                        <li> Psychological self-evaluations and psychological examinations</li>
                        <li> Self-Therapy modules</li>
                        <li> Workshops and/or webinars presented by qualified Psychological Wellness Experts</li>
                        <li> Self-help resources, content, and programs accessible via several media, including but not
                            limited to websites, mobile applications, and emails</li>
                        <li> Guide chat bundles that enable clients to exchange encrypted private messages with their
                            Psychological Wellness Professional, in addition to online counselling.</li>
                        <li> Participation in user forums, topics, replies, comments, feedback, blogs, and other
                            submissions on the platform; THAP has the right to add or withdraw items and Services
                            without prior notice. The aforementioned shall hereafter be known as "Services."</li>
                    </ul>
                </div>
                <div class="text-sec">
                    <h4>Consent</h4>
                    <p>By using the platform, supplying us with your Personal Information, utilizing the platform's
                        features, or making a payment to THAP, you consent to the collection, storage, processing,
                        disclosure, and transfer of your personal information in accordance with the terms of this
                        Privacy Statement.</p>
                    <p>You acknowledge that you are voluntarily supplying THAP with your Personal Information, either
                        directly or through a third party or your organization. You have the option to not provide us
                        with the requested personal information. You will also have the option to withdraw your consent
                        at any time, provided that such withdrawal is communicated to support@thap.app in writing.
                        Furthermore, if you are accessing our platform via a third party or your organization, you will
                        have the ability to withdraw your consent at any time, provided that you notify the third party
                        or your organization in writing, who will then inform us to take the relevant action.

                        If you do not wish to give us with your Personal Information or if you revoke your consent at
                        any time, we reserve the right to not fulfil the services for which the Personal Information was
                        collected and to limit your use of the platform.</p>
                </div>
                <div class="text-sec">
                    <h4>Personal Information</h4>
                    <p>To enable you to engage with our Services, we will use your personal information to contact or
                        identify you, including your name, phone number, emergency contact number, gender, occupation,
                        hometown, personal interests, your email address, reason(s) for canceling an appointment with a
                        healthcare professional, medical history, and any other information that the Wellness
                        Professional may require. We also gather the information you supply through feedback. If you
                        contact us by email or phone, any information you submit may be collected as personal
                        information ("Personal Information").</p>
                    <p>The primary purpose of collecting this Personal Information is to provide you with a seamless,
                        efficient, and personalized experience. The acquisition of Personal Information also allows the
                        user to build a profile and account that may be used to communicate with our wellness
                        professionals. You can modify some of the information you supply through your account page on
                        the website or your mobile application's profile information (s).
                        We may use your Personal Information to:</p>
                    <ul>
                        <li> Identify and communicate with you</li>
                        <li> Resolve service and billing issues over the phone or via email.</li>
                        <li> Assist with appointment scheduling, remind you of future or follow-up visits, as well as
                            cancelled appointments.</li>
                        <li> Send you additional information, products, and services, as well as newsletters.</li>
                        <li> Better understand users' needs and interests.</li>
                        <li> Personalize your experience.</li>
                        <li> Conduct statistical research (such research will only use your information anonymously and
                            cannot be traced back to you)</li>
                        <li>Detect and defend us against error, fraud, and other illegal activities.</li>
                        <li>Disclose information as needed by applicable law.</li>
                        <li>Enhance our website and mobile application(s) to serve you better.</li>
                        <li>Permit us to reply to your customer service queries with a higher level of efficiency.</li>
                        <li>Conduct a contest, promotion, survey, or other function of a website or mobile application.
                        </li>
                        <li> Process your transactions quickly.</li>
                        <p>Wellness Professionals and our app's algorithms analyze your information to better assess
                            your condition and give you with the most appropriate counseling service or digital
                            experience. Your Personal Information is never shared with other Wellness Professionals by
                            the Wellness Professional who is assisting you. On occasion, however, THAP and/or Wellness
                            Professionals may employ third-party tools to customize counselling sessions and in-app
                            experiences. In such circumstances, minimal information is shared with others.</p>
                        <p>We are committed to protecting the confidentiality and security of your Personal Information.
                            You can notify us via email at support@thap.app if you no longer wish to receive specific
                            communications from us at any time.</p>
                    </ul>
                </div>
                <div class="text-sec">
                    <h4>Changing Personal Information</h4>
                    <p>If your Personal Information changes, or if you need to update or correct your Personal
                        Information, or if you have any grievance regarding the processing or use of your Personal
                        Information, you may send updates and corrections to us at support@thap.app, and we will make
                        all reasonable efforts to incorporate the changes within a reasonable timeframe.</p>
                    <p>If you send your Personal Information to a third-party platform from which you are accessing our
                        Services, THAP may not be able to make any changes, and you will need to contact the third-party
                        platform in order to correct your Personal Information.</p>
                    <p>If your Personal Information is saved on the platform as part of your profile, you can change it
                        via our mobile application (s).</p>
                    <p>Once submitted, certain Personal Information cannot be modified or deleted. If you would want us
                        to remove your records from our system, please contact us at support@thap.app, and we will try
                        to comply if we are not required by law to maintain them.</p>
                    <p>Please note that we are required to retain certain information in accordance with professional
                        standards or by law for record-keeping purposes (including, but not limited to, payment history,
                        feedback, client information, etc.), so even if you delete your account, we will continue to
                        store this information for a predetermined period of time in accordance with applicable laws.
                        There may also be residual information within our databases and other records that, despite our
                        efforts to remove it, cannot be erased.</p>
                </div>
                <div class="text-sec">
                    <h4>Cookies</h4>
                    <p>To collect information and improve your experience on our platform, we deploy "cookies." A cookie
                        is a little data file that we save on your device's hard drive to keep track of your
                        preferences. Cookies serve two purposes for us. First, persistent cookies may be used to save
                        your user credentials for future logins to the Services. Second, session ID cookies may be used
                        to enable certain features of the Services, to better understand how you interact with the
                        Services, and to track aggregate usage and online traffic routing on the Services. Session
                        cookies, unlike persistent cookies, are erased from your device when you log off from the
                        Services.</p>
                    <p>To improve your user experience, we may collaborate with third parties who install or read
                        cookies on your device. By using third-party services through our platform, you agree to their
                        Privacy Policies and Terms of Service, and you agree not to hold THAP liable for any
                        difficulties that arise as a result of such use.
                        You can instruct your device to stop accepting cookies or to prompt you before accepting a
                        cookie from a website you visit by modifying its options. However, if you refuse to allow
                        cookies, you may not be able to enjoy all of the platform's features or functions.
                    </p>
                </div>
                <div class="text-sec">
                    <h4>Log Data</h4>
                    <p>Our servers automatically record information sent by your mobile application ("Log Data") when
                        you access the platform. This Log Data may include your computer's IP address, browser type,
                        device name, operating system version, app configuration when accessing the Platform, the
                        webpage you were visiting before coming to our Services, pages of our platform and Services that
                        you visit, the time spent on those pages, information you search for on our Services, access
                        times and dates, and other statistics. We use this information to track your location, analyze
                        trends, administer the site, compile broad demographic data for aggregate usage, improve
                        user-friendliness, and adapt our Services to better meet your needs.</p>
                </div>
                <div class="text-sec">
                    <h4>Confidentiality</h4>
                    <p>The information shared during a personal consultation is kept confidential by THAP. Any
                        information shared with THAP is confidential and will not be shared with anybody, including your
                        company or institution with certain exceptions where confidentiality may be breached. The case
                        where confidentiality will be breached is if:</p>
                    <ul>
                        <li>If the Wellness Professional or THAP believes there is a substantial and/or considerable
                            and/or imminent danger of harm to a person's health or safety, or</li>
                        <li>If disclosure is required by law, confidentiality will be compromised.</li>
                        <li>If you submit a private healthcare claim, and the insurer requests additional information
                        </li>
                        <p>Except for the reasons stated above, Personal Information shared on THAP will only be shared
                            with others after you have given consent orally, in writing, or by fax. This confidentiality
                            policy applies to all members of the THAP team, including all THAP professionals, employees,
                            contracted professionals, and trainees.</p>
                        <p>The User agrees to indemnify THAP for any breach in confidentiality of the User’s Personal
                            Information. If the User accesses THAP through a third-party platform the User indemnifies
                            THAP against any data breaches that occur due to any acts of commission or omission from the
                            third-party platform.</p>
                    </ul>
                </div>
                <div class="text-sec">
                    <h4>Third Party Disclosure</h4>
                    <p>THAP does not sell or trade your Personal Information to third parties unless we provide you with
                        advance notice. This however does not apply to any storage or transfer to and from
                        server/website hosting partners and other parties who assist us in operating our platform,
                        conducting our business, or servicing you. We may also release your information when we believe
                        release is appropriate to comply with the law, enforce our site policies, mobile application
                        policies, or protect ours or others’ rights, property, or safety. However, visitor information
                        that is not personally identifiable may be provided to other parties for marketing, advertising,
                        or other uses.

                        Security
                    </p>
                    <p>We apply administrative, physical, and technical safeguards to prevent unauthorized access, use,
                        and disclosure of information under our control. When we collect, keep, access, use, or disclose
                        your Personally Identifiable Information, we will do so using systems and procedures that adhere
                        to industry standards for data privacy and security.</p>
                    <p> Calls and video sessions are not recorded. Wellness Professionals may be expected, in accordance
                        with professional standards, to retain records of both online and offline sessions.</p>
                    <p>Despite the security safeguards we have implemented, we strongly discourage you from sharing
                        Personal Information in forums, comments, or other publicly available areas of our site. THAP is
                        not liable for the use or misuse of any information belonging to or given by the User in
                        connection with its Services. The User will not hold THAP liable for any data storage and/or
                        security-related issues.</p>
                    <p>You are responsible for ensuring the privacy and security of your email account and phone
                        communications so that third parties cannot access them. THAP will interact with you via one or
                        both of these channels regarding a variety of facts linked to your psychological health. If your
                        email or text messages are accessed by a third party, with or without your permission, THAP
                        shall not be liable for any breach of confidentiality.</p>
                </div>
                <div class="text-sec">
                    <h4>Links</h4>
                    <p>The platform may contain links to websites operated by other parties. The sites of third parties
                        are not necessarily under THAP's control. Please be aware that THAP is not responsible for the
                        privacy policies of these sites. THAP advises you to be mindful when you leave the Platform and
                        to read the privacy policies of every third-party website and mobile application that collects
                        Personal Information. If you decide to visit any of the third-party websites that are linked to
                        the platform, you do so at your own risk. Links to partner websites are the responsibility of
                        the connecting party, and THAP is not responsible for notifying users of any changes to the
                        website's name or location of any information. THAP is not responsible for the use of any
                        Personal Information that you voluntarily give via a forum or website.</p>
                </div>
                <div class="text-sec">
                    <h4>Individuals Under the Age of 18</h4>
                    <p>Our Services, website, and mobile application(s) are not intended for those under 18 years old.
                        We do not knowingly solicit anyone under the age of 18 to participate independently in any of
                        our Services.</p>
                </div>
                <div class="text-sec">
                    <h4>Changes To Privacy Policy</h4>
                    <p>THAP retains the right to modify or eliminate any portion of this Privacy Statement without prior
                        notice or obligation to any third party. If there are substantial changes to the way we process
                        your Personal Information or to our Privacy Statement, we may, but are not bound, to post a
                        notice on the website or app or send you an email so that you can review the revised terms
                        before continuing to use the platform.

                        As always, if you disagree with any of the modifications to our terms and no longer wish to use
                        the platform, you can contact THAP to quit using our platform and delete your account if you are
                        registered with us directly. If you signed up for our platform through a third-party platform or
                        are using our Services through your workplace, you must contact the third-party platform or your
                        employer in order to delete your account or cease using our Services.

                        Our current Privacy Policy applies to all information THAP possesses on you and your account,
                        unless otherwise specified. Using the Services on the websites, mobile application(s) or
                        accessing the websites, mobile application(s) after a notice of change has been emailed to you
                        or posted on our website constitutes your consent of the modified terms.</p>
                </div>
                <div class="text-sec">
                    <h4>Grievance Redressal</h4>
                    <p>To address the grievances of the users, THAP has set up a Grievance Redressal Forum. In case you
                        are dissatisfied with any aspect of our Services, you may contact us at support@thap.app. We
                        assure you a time-bound response not exceeding one month from the date of your complaint.</p>
                </div>
                <div class="text-sec">
                    <h4>Jurisdiction</h4>
                    <p>Jurisdictional policies of THAP have been drafted in accordance and compliance with Indian laws.
                        Any and all disputes arising between the user and THAP with regards to this Policy, including
                        the interpretation of the terms of this Policy shall be subject to the exclusive jurisdiction of
                        the courts at Bengaluru, Karnataka, India.</p>
                </div>
                <div class="text-sec">
                    <h4>Contact Information</h4>
                    <p>Registered Address: 175 & 176, Bannerghatta Main Rd, Dollars Colony, Phase 4, J. P. Nagar,
                        Bengaluru, Karnataka 560076</p>
                    <p><a href="mailto:support@thap.app">E-mail: support@thap.app</a></p>
                </div>
            </div>
        </div>
    </div>
    <script src="assets/js/boot.min.js"></script>
    </>
}

export default Policies;